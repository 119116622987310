/**
 * b.square custom styles
 */
body {
    overflow-y: scroll;
}

.profile-label {
    display: inline-block;
    margin-bottom: .2em;
    font-size: 12px;
    background-color: #d2d6de;
    color: #444;
    margin-right: .2em;
}

/* Wrapper for page content to push down footer */
#wrap {
    min-height: 100%;
    height: auto;
    /* Negative indent footer by its height */
    margin: 0 auto -60px;
    /* Pad bottom by footer height */
    padding: 0 0 60px;
}

.field-heading {
    margin-top: 30px;
    text-transform: uppercase;
    font-weight: 800;
    margin-bottom: 15px;
}

input.form-control[readonly] {
    background-color: #fff;
}

table.timeslots .slot > div {
    width: 100%;
}

table.timeslots .slot.suggested.editable i
{
    font-size: 18px;
}

table.timeslots .slot .star-container
{
    display: none;
    height: 100%;
    width: 100%
}

table.timeslots .slot.suggested .star-container
{
    display: table;
}

table.timeslots .slot span
{
    z-index: 2;
    text-shadow: 0 0 1px #333;
}

table.timeslots .slot .star-container i
{
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    width: 100%;
    font-size: 17px;
    opacity: 0.8;
}

table.timeslots .slot {
    border: 2px solid #FFF;
    cursor: pointer;
    border-radius: 7px;
    text-align: center;
    color: #FFF;
    font-size: 12px;
    padding: 6px;
}

.timetable_buttons .btn
{
    margin-bottom: 5px;
}

footer {
    background-color: #f5f5f5;
}

footer .navbar-nav > li > a {
    color: #777;
}

div.dataTables_wrapper div.dataTables_filter {
    float: right;
}

div.dataTables_wrapper div.dataTables_info {
    float: left;
}

div.dataTables_wrapper div.dataTables_length label {
    float: right;
    padding-right: 40px;
}

table .meetingrequest_status:hover {
    cursor: default;
}

.navbar-nav.navbar-center {
    text-align: center;
}

.navbar-nav.navbar-center > li {
    float: none;
    display: inline-block;
}

.dashboard-shortcut:hover, .dashboard-shortcut:focus {
    text-decoration: none;
}

.bg-red.disabled, .bg-green.disabled, .bg-gray.disabled {
    opacity: .65;
    filter: alpha(opacity=65);
    cursor: default !important;
}

.slot.bg-green {
    background-color: #5cb85c;
}

.slot.bg-blue
{

}

.label-error, .slot.bg-red {
    background-color: #dd4b39 !important;
}

.bg-gray {
    background-color: #949494 !important;
    color: #FFF !important;
}

.bg-teal {
    background-color: #39cccc !important;
    color: #FFF !important;
}

.bg-yellow {
    background-color: #f39c12 !important;
    color: #FFF !important;
}

.bg-green {
    background-color: #00a65a !important;
    color: #FFF !important;
}

.bg-aqua {
    background-color: #00c0ef !important;
    color: #FFF !important;
}

.bg-red {
    background-color: #dd4b39 !important;
    color: #FFF !important;
}

.bg-locked {
    background-color: #B6DDFF !important;
}

.bg-orange
{
    background-color: #ff851b !important;
    color: #FFF !important;
}

.bg-purple
{
    background-color: #9561e2;
    color: #FFF !important;
}

.bg-panel {
    background-color: #ffdd95 !important;
}

.bg-gray {
    background-color: #949494 !important;
    color: #FFF !important;
}

.color-red {
    color: #dd4b39 !important;
}

.color-locked {
    color: #B6DDFF !important;
}

.color-gray {
    color: #949494;
}

.timeslots-table-container
{
    max-width: 100%;
    overflow-x: auto;
}

table.dataTable {
    border-collapse: collapse;
    width: 100% !important;
}

.table > thead > tr > th, .table > thead > tr > td, .table > tbody > tr > th, .table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td {
    padding: 7px;
    vertical-align: middle;
    border-top: none;
    text-align: center;
}

.table tr.time td {
    font-size: 12px;
}

.timeslots-table-container.dashboard a {
    text-decoration: none;
    color: inherit;
}

.timeslots-table-container.dashboard {
    overflow-x: auto;
}

table.dashboard tr.day {
    font-size: 12px;
}

#timeslots.dashboard .slot {
    font-size: 10px;
}


p.dashboard-timetable-alert {
    font-weight: 800;
    text-align: center;
}

table.dashboard > tbody > tr > td {
    padding: 3px;
}

.header.img-responsive {
    margin: auto;
    padding-bottom: 20px;
}

.form-control-show {
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    color: #555555;
    background-color: #fff;
    background-image: none;
}

.form-group.required .control-label span.required {
    color: red;
}

.control-label p.small {
    font-weight: normal;
}

.padded-top {
    padding-top: 7px;
}

.registration-info {
    padding-bottom: 30px;
}

.extra-small {
    font-size: 60%;
}

.colofon img {
    width: 100%;
}

.print-only {
    display: none !important;
}

.table > tbody > tr > td.nowrap {
    white-space: nowrap;
}

.filterable .filter.not-visible {
    padding: 0;
    opacity: 0;
    height: 0;
}

.filterable .filter {
    padding: 15px 0;
    opacity: 1;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}

.modal-panel {
    margin-bottom: 0px;
    border: none;
    overflow-y: initial !important
}

.modal-panel .panel-body {
    max-height: 80vh;
    overflow-y: auto;
}

.modal-panel p.fullname
{
    font-size: 14px;
    margin-bottom: 0;
}

.modal-panel p.company
{
    font-weight: 800;
    font-size: 16px;
    margin-bottom: 0;
}

.toast-title {
    font-weight: 700;
}

.toast-message {
    -ms-word-wrap: break-word;
    word-wrap: break-word;
}

.toast-message a, .toast-message label {
    color: #fff;
}

.toast-message a:hover {
    color: #ccc;
    text-decoration: none;
}

.toast-close-button {
    position: relative;
    right: -.3em;
    top: -.3em;
    float: right;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    -webkit-text-shadow: 0 1px 0 #fff;
    text-shadow: 0 1px 0 #fff;
    opacity: .8;
    -ms-filter: alpha(Opacity=80);
    filter: alpha(opacity=80);
}

.toast-close-button:focus, .toast-close-button:hover {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    opacity: .4;
    -ms-filter: alpha(Opacity=40);
    filter: alpha(opacity=40);
}

button.toast-close-button {
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    -webkit-appearance: none;
}

.toast-top-center {
    top: 0;
    right: 0;
    width: 100%;
}

.toast-bottom-center {
    bottom: 0;
    right: 0;
    width: 100%;
}

.toast-top-full-width {
    top: 0;
    right: 0;
    width: 100%;
}

.toast-bottom-full-width {
    bottom: 0;
    right: 0;
    width: 100%;
}

.toast-top-left {
    top: 12px;
    left: 12px;
}

.toast-top-right {
    top: 12px;
    right: 12px;
}

.toast-bottom-right {
    right: 12px;
    bottom: 12px;
}

.toast-bottom-left {
    bottom: 12px;
    left: 12px;
}

#toast-container {
    position: fixed;
    z-index: 999999;
}

#toast-container * {
    box-sizing: border-box;
}

#toast-container > div {
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 15px 15px 15px 50px;
    width: 300px;
    border-radius: 3px;
    background-position: 15px center;
    background-repeat: no-repeat;
    box-shadow: 0 0 12px #999;
    color: #fff;
    opacity: .8;
    -ms-filter: alpha(Opacity=80);
    filter: alpha(opacity=80);
}

#toast-container > :hover {
    box-shadow: 0 0 12px #000;
    opacity: 1;
    -ms-filter: alpha(Opacity=100);
    filter: alpha(opacity=100);
    cursor: pointer;
}

#toast-container > .toast-info {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=) !important;
}

#toast-container > .toast-error {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=) !important;
}

#toast-container > .toast-success {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==) !important;
}

#toast-container > .toast-warning {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=) !important;
}

#toast-container.toast-bottom-center > div, #toast-container.toast-top-center > div {
    width: 300px;
    margin: auto;
}

#toast-container.toast-bottom-full-width > div, #toast-container.toast-top-full-width > div {
    width: 96%;
    margin: auto;
}

.toast {
    background-color: #030303;
}

.toast-success {
    background-color: #51a351;
}

.toast-error {
    background-color: #bd362f;
}

.toast-info {
    background-color: #2f96b4;
}

.toast-warning {
    background-color: #f89406;
}

.toast-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: #000;
    opacity: .4;
    -ms-filter: alpha(Opacity=40);
    filter: alpha(opacity=40);
}

.panel .panel-heading > .row
{
    padding-bottom: 0;
}

.panel .panel-heading .btn-help
{
    margin: 0;
}

.dataTable
{
    font-size: 14px;
}

footer > .row
{
    padding: 0;
}

table.agenda
{
    font-size: 14px;
}

/* WIDGETS */

.info-box {
    display: block;
    min-height: 90px;
    width: 100%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    margin-bottom: 15px;
}

.info-box-icon {
    border-top-left-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 2px;
    display: block;
    float: left;
    height: 90px;
    width: 90px;
    text-align: center;
    font-size: 45px;
    line-height: 90px;
    background: rgba(0, 0, 0, 0.2);
}

.info-box-content {
    padding: 5px 10px;
    margin-left: 90px;
}

.info-box-text {
    text-transform: uppercase;
}

.progress-description, .info-box-text {
    display: block;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.info-box-number {
    display: block;
    font-weight: bold;
    font-size: 18px;
}

.info-box .progress, .info-box .progress .progress-bar {
    border-radius: 0;
    background: #fff;
}

.info-box .progress {
    background: rgba(0, 0, 0, 0.2);
    margin: 5px -10px 5px -10px;
    height: 2px;
}

.progress, .progress > .progress-bar {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.progress-description {
    margin: 0;
}

.bigcart {
    background: url(../../uploads/images/sprite.png);
    background-position: 0px 11px;
    background-repeat: no-repeat;
    width: 155px;
    height: 120px;
    margin: 0 0 40px 60px;
}

.col-md-5.col-sm-12 h1 {
    color: #595c5f;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: left;
}

.col-md-5.col-sm-12 p {
    color: #898e92;
    line-height: 1.5;
    max-width: 400px;
    text-align: justify;
}

ul.cart-ul {
    list-style-type: none;
}

.columnCaptions {
    color: #7e93a7;
    font-size: 12px;
    text-transform: uppercase;
    padding: 0;
    box-shadow: 0 0 0;
    background-color: #f2f6f9;
}

.cart-ul .columnCaptions span:first-child {
    padding-left: 8px;
}

.cart-ul .columnCaptions span {
    padding: 0 21px 0 0;
}

.cart-ul .columnCaptions span:last-child {
    float: right;
    padding-right: 72px;
}

.cart {
    background-color: #f2f6f9;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    float: left;
    border-radius: 4px;
}

.cart-ul .row {
    box-shadow: 0 1px 0 #e1e5e8;
    padding-bottom: 0;
    padding-left: 15px;
    margin-bottom: 11px;
    background-color: #ffffff;
    float: left;
    width: 100%;
}

.cart-ul .row span {
    padding: 20px 15px;
}

.quantity {
    color: #4ea6bc;
    font-size: 18px;
    font-weight: bold;
    float: left;
    width: 42px;
    padding-left: 7px;
}

.itemName {
    color: #727578;
    font-size: 16px;
    font-weight: bold;
    float: left;
    padding-left: 25px;
}

.price {
    color: #f06953;
    font-size: 18px;
    font-weight: bold;
    float: right;
}

.cart-ul .row.totals {
    background-color: #f2f6f9;
    box-shadow: none;
}

.cart-ul .row .paypal-button span {
    padding: 1px;
}

.cart-ul .paypal-button {
    padding-top: 8px;
    float: right;
}

.totals .itemName {
    margin-top: 1px;
}

.totals span {
    padding: 40px 15px 40px 0;
}

.totals .price {
    float: left;
}

.totals .order {
    float: right;
    padding: 0;
    padding-left: 5px;
    cursor: pointer;
}

.order a {
    background-color: #f08573;
    color: #fbfffa;
    font-weight: bold;
    border-radius: 2px;
    padding: 20px 30px;
    text-decoration: none;
}

.voucher-form input[type=submit] {
    background-color: #f08573;
    color: #fbfffa;
    font-weight: bold;
    border-radius: 2px;
    border: 0px;
    padding: 0;
    font-size: 14px;
    margin-left: 23px;
    margin-top: -12px;
    height: 50px;
    width: 130px;
    box-sizing: content-box;
}

.voucher-form input[type=text] {
    height: 50px;
    width: 260px;
    border: 0px;
    border-radius: 2px;
    font-size: 25px;
    padding-left: 10px;
}

.voucher-form button {
    height: 57px;
    width: 100px;
    border: 0px;
    background-color: #f08573;
    border-radius: 2px;
    padding: 0px;
}

.voucher-form button a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    padding: 20px 25px
}

.voucher-form .row.code {
    padding-top: 80px;
}

.voucher-form .row.buttons {
    padding-top: 20px;
}

.paypal-button.paypal-style-checkout.paypal-size-small .paypal-button-content {
    border-radius: 1px !important;
}

.btn {
    margin: 10px 0;
}

.legend {
    display: table;
    margin: 20px auto;
}

.profile-info, .application-form-info {
    margin-top: 10px;
}

.buttons .btn {
    margin: 5px;
}

.panel-heading {
    position: relative;
}

.panel-heading .help {
    position: absolute;
    right: 5px;
    top: 2px;
}

.btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
}

.btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 25px;
}

.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    font-size: 24px;
    line-height: 1.33;
    border-radius: 35px;
}
.flags {
    padding: 15px 0;
}
.flags img {
    width: 20px;
}
.flags ul li {
    list-style: none;
    float: left;
    padding: 0 5px;
}
td.order {
    cursor: pointer;
}

/*table tbody td .btn-xs {
    padding: 3px 0;
    width: 25px;
}*/

.flag {
    width: 30px;
    max-width: 100%;
    height: auto;
    border: 1px solid #e3e3e3;
}

li.flags > ul
{
    padding: 0;
}

/* Timeline */

.timeline {
    position: relative;
    margin: 0 0 30px 0;
    padding: 0;
    list-style: none;
}
.timeline:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 4px;
    background: #ddd;
    left: 31px;
    margin: 0;
    border-radius: 2px;
}
.timeline>li {
    position: relative;
    margin-right: 10px;
    margin-bottom: 15px;
}
.timeline>li:before, .timeline>li:after {
    content: " ";
    display: table;
}
.timeline>li:after {
    clear: both;
}
.timeline>.time-label>span {
    font-weight: 600;
    padding: 5px;
    display: inline-block;
    background-color: #fff;
    border-radius: 4px;
}
.timeline>li>.fa, .timeline>li>.glyphicon, .timeline>li>.ion {
    width: 30px;
    height: 30px;
    font-size: 15px;
    line-height: 30px;
    position: absolute;
    color: #666;
    background: #d2d6de;
    border-radius: 50%;
    text-align: center;
    left: 18px;
    top: 0;
}
.timeline>li>.timeline-item {
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.1);
    box-shadow: 0 1px 1px rgba(0,0,0,0.1);
    border-radius: 3px;
    margin-top: 0;
    background: #fff;
    color: #444;
    margin-left: 60px;
    margin-right: 15px;
    padding: 0;
    position: relative;
}
.timeline>li>.timeline-item>.timeline-header {
    margin: 0;
    color: #555;
    border-bottom: 1px solid #f4f4f4;
    padding: 10px;
    font-size: 16px;
    line-height: 1.1;
}
.timeline>li>.timeline-item>.timeline-body, .timeline>li>.timeline-item>.timeline-footer {
    padding: 10px;
}

.topbar {
    position: fixed;
    width: 100%;
    z-index: 100;
}

#wrap > .container
{
    padding-top: 100px;
}

.select2-container .select2-selection--single
{
    height: 32px;
}

.panel.meeting-requests .nav-tabs > li
{
    width: 50%;
    text-align: center;
}

.panel.meeting-requests .nav-tabs > li > a
{
    border-radius: 0;
    background-color: #fff;
    border: 0;
    color: #555;
    cursor: pointer;
    margin: 0;
}

.panel.meeting-requests .nav-tabs > li.active > a,
.panel.meeting-requests .nav-tabs > li.active > a:hover,
.panel.meeting-requests .nav-tabs > li.active > a:focus
{
    background-color: #f5f5f5;
    border: 1px solid #ddd;
}

div.logged-in-as
{
    margin: 0;
    border-radius: 0;
    position: fixed;
    width: 100%;
    font-size: 11px;
    padding: 3px 10px;
    text-align: center;
    z-index: 100;
}

.panel-body
{
    position: relative;
}

.loading {
    position: absolute;
    z-index: 700;
    height: 100%;
    width: 100%;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
}

.inner-loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
    width: 55px;
    height: 55px;
    margin: auto;
}

.form-group textarea
{
    resize: vertical;
}


/* boxes css*/
.stack-content {
    padding: 8px;
    background-color: #F6F6F6;
    border-top: 1px solid #D1D1D1;
    color: #AE0E0E;
    font-family: consolas,sans-serif;
    font-size: 12px;
}

.info-box.level {
    display: block;
    padding: 0;
    min-height: 70px;
    background: #fff;
    width: 100%;
    box-shadow: 0 1px 1px rgba(0,0,0,0.1);
    border-radius: 2px;
    margin-bottom: 0;
    position: relative;
}

.info-box.level.small {
    min-height: 45px;
    border-radius: 3px 3px 0 0;
}

.info-box.level.small.btn
{
    border-radius: 4px;
}

.info-box.level.small .info-box-icon
{
    position: absolute;
    top: 0;
    left: 0;
    height: 45px;
    width: 45px;
}

.info-box.level.small .info-box-icon i
{
    font-size: 20px;
}


.info-box.level.small .info-box-content
{
    padding: 0;
    margin: 0;
    display: table;
    text-align: center;
    width: 100%;
    height: 100%;
}

.info-box.level.small .info-box-text
{
    display: table-cell;
    vertical-align: middle;
    height: 45px;
}

.info-box.level.small .info-close
{
    position: absolute;
    right: 0;
    top: 0;
    height: 45px;
    width: 45px;
    margin: 0;
    display: table;
    border-radius: 0;
    border: 0;
    cursor: pointer;
    background: rgba(0,0,0,0.2);
    color: #fff;
}

.info-box.level.small .info-close.bg-white
{
    background: #fff;
    border-left: 1px solid #e3e3e3;
    color: #333;
}

.info-box.level.small .info-close span
{
    display: table-cell;
    vertical-align: middle;
    font-size: 22px;
    font-weight: 800;
}


.info-box.level .info-box-text,
.info-box.level .info-box-number,
.info-box.level .info-box-icon > i {
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}

.info-box.level .info-box-icon > i {
    display: table-cell;
    vertical-align: middle;
    font-size: 30px;
}
.info-box.level .info-box-text {
    display: block;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.info-box.level .info-box-content {
    padding: 5px 10px;
    margin-left: 70px;
    margin-right: 70px;
}

.info-box.level .info-box-number {
    display: block;
    font-weight: bold;
    font-size: 18px;
}

.info-box.level .info-box-icon {
    border-radius: 2px 0 0 2px;
    display: table;
    height: 70px; width: 70px;
    text-align: center;
    font-size: 40px;
    line-height: 70px;
    background: rgba(0,0,0,0.2);
}

/*.info-box.level .toggle-button*/
/*{*/
/*    height: 70px;*/
/*    width: 70px;*/
/*    position: absolute;*/
/*    right: 0;*/
/*    top: 0;*/
/*    display: table;*/
/*    background-color: rgba(0,0,0,0.2);*/
/*}*/

.info-box.level .toggle-button i {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-size: 20px;
    transition-duration: 0.5s;
}

.info-box.level.collapsed .toggle-button i
{
    transform: rotate(180deg);
}



.info-box.level .progress {
    background: rgba(0,0,0,0.2);
    margin: 5px -10px 5px -10px;
    height: 2px;
}

.info-box.level .progress .progress-bar {
    background: #fff;
}

.info-box.level-empty {
    opacity: .6;
    -webkit-filter: grayscale(1);
    -moz-filter: grayscale(1);
    -ms-filter: grayscale(1);
    filter: grayscale(1);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-transition-property: -webkit-filter, opacity;
    -moz-transition-property: -moz-filter, opacity;
    -o-transition-property: filter, opacity;
    transition-property: -webkit-filter, -moz-filter, -o-filter, filter, opacity;
}

.info-box.level-empty:hover {
    opacity: 1;
    -webkit-filter: grayscale(0);
    -moz-filter: grayscale(0);
    -ms-filter: grayscale(0);
    filter: grayscale(0);
}

.level {
    padding: 2px 6px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    font-size: .9em;
    font-weight: 600;
}

.badge.level-all,
.badge.level-emergency,
.badge.level-alert,
.badge.level-critical,
.badge.level-error,
.badge.level-warning,
.badge.level-notice,
.badge.level-info,
.badge.level-debug,
.level, .level i,
.info-box.level-all,
.info-box.level-emergency,
.info-box.level-alert,
.info-box.level-critical,
.info-box.level-error,
.info-box.level-warning,
.info-box.level-notice,
.info-box.level-info,
.info-box.level-debug {
    color: #FFF !important;
}

.label-env {
    font-size: .85em;
}

.badge.level-all, .level.level-all, .info-box.level-all {
    background-color: #8A8A8A;
}

.badge.level-emergency, .level.level-emergency, .info-box.level-emergency {
    background-color: #B71C1C;
}

.badge.level-alert, .level.level-alert, .info-box.level-alert  {
    background-color: #D32F2F;
}

.badge.level-critical, .level.level-critical, .info-box.level-critical {
    background-color: #F44336;
}

.badge.level-error, .level.level-error, .info-box.level-error {
    background-color: #FF5722;
}

.badge.level-warning, .level.level-warning, .info-box.level-warning {
    background-color: #FF9100;
}

.badge.level-notice, .level.level-notice, .info-box.level-notice {
    background-color: #4CAF50;
}

.badge.level-info, .level.level-info, .info-box.level-info {
    background-color: #1976D2;
}

.badge.level-debug, .level.level-debug, .info-box.level-debug {
    background-color: #90CAF9;
}

.badge.level-empty, .level.level-empty {
    background-color: #D1D1D1;
}

.badge.label-env, .level.level-env {
    background-color: #6A1B9A;
}

.badge.label-panel, .level.level-panel {
    background-color: #ffdd95;
}


.clickable:hover
{
    cursor: pointer;
}

.profile-avatar
{
    width: 100%;
    height: 100%;
    max-width: 100px;
    max-height: 100px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    margin-bottom: 5px;
}

.profile-avatar > img
{
    border-radius: 50%;
    width: 100px;
    height: 100px;
}

.profile p.form-control-show
{
    padding-left: 5px;
}

.btn.file-btn {
    position: relative;
    padding: 5px 20px;
    background: #f3f3f3;
}

.btn.file-btn input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.upload-canvas-wrapper .upload-buttons
{
    width: 180px;
    margin: auto;
}

.navbar-nav .img-circle {
    float: left;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: -2px;
}

/* requests messages */
.msg-container
{
    position: relative;
}

.msg-container .msg-image .image-frame
{
    position: absolute;
    top: 0;
    background: #fff;
    height: 80px;
    width: 80px;
    text-align: center;
    border-radius: 50%;
}

.msg-container .msg-image img
{
    border-radius: 50%;
    height: 70px;
    width: 70px;
    margin-top: 5px;
    box-shadow: 0 0 1px #333;
}

.msg-container.right .msg-image .image-frame
{
    right: 0;
}

.msg-container.left .msg-image .image-frame
{
    left: 0;
}

.msg-container .msg-text
{
    padding-top: 30px;
    padding-left: 45px;
    padding-right: 45px;
}



.msg-container .msg-text .header
{
    height: 45px;
    display: table;
    width: 100%;
    font-weight: 800;
    font-size: 12px;
}

.msg-container.right .msg-text .header,
.msg-container.right .msg-text .body
{
    background-color: #e3e7eaa8;
}

.msg-container.left .msg-text .header,
.msg-container.left .msg-text .body
{
    background-color: #b7d4eca8;
}

.msg-container .msg-text .header p
{
    display: table-cell;
    margin-bottom: 5px;
    vertical-align: middle;
}

.msg-container.left .msg-text .header
{
    text-align: left;
    border-radius: 0 10px 0 0;
    padding-left: 46px;
}




.msg-container.right .msg-text .header
{
    text-align: right;
    border-radius: 10px 0 0 0;
    padding-right: 46px;
}





.msg-container .msg-text .body
{
    padding: 15px 35px;
    padding-top: 10px;
    border-radius: 0 0 10px 10px;
}

.msg-container .msg-text textarea.body
{
    padding: 10px 35px;
    border: none;
    width: 100%;
    margin: 0;
    resize: vertical;
}

.msg-container .msg-text textarea.body:focus
{
    outline-width: 0;
}

.msg-container.right .msg-text .body,
.msg-container.right .msg-text textarea.body
{
    padding-right: 46px;
}

.msg-container.left .msg-text .body,
.msg-container.left .msg-text textarea.body
{
    padding-left: 46px;
}

.person-label p
{
    line-height: 1;
    margin-bottom: 3px;
}

.person-label img
{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 0 1px #333;
}

.middle-top.container-fluid
{
    padding: 0;

}

.cursor-pointer
{
    cursor: pointer;
}

.cursor-default
{
    cursor: default;
}

.login-buttons {
    display: flex;
    width: 100%;
    justify-content: center;
}

.login-buttons .login-button {
    flex: 1;
}

.login-footer {
    position: relative;
    padding-top: 15px;
}

.login-footer p.intro-text {
    position: absolute;
    top: -12px;
    width: 100%;
}

.login-footer p.intro-text span {
    z-index: 1;
    background: #fff;
    padding: 0 12px;
}

.btn.external-login {
    display: flex;
    background: #fff;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 3px;
    color: #000;
    text-decoration: none;
    height: 40px;
    width: 280px;
    align-items: center;
    margin: auto;
    padding: 0;
}

.btn.external-login:hover {
    background: #ccc;
}

.btn.external-login span {
    margin: auto;
}

.btn.external-login::before {
    min-height: 40px;
    min-width: 40px;
    border-radius: 3px 0 0 3px;
    border: 1px solid #ccc;
    content: "";
}

.btn.external-login.cinando {
    background: #7eb712;
    border: 0;
    color: #fff;
}

.btn.external-login.cinando:hover {
    background: #90ce14;
}

.btn.external-login.cinando::before {
    background-size: 30px 30px;
}

.btn.external-login.eventival {
    background: #06b8e7;
    border: 0;
    color: #fff;
}

.btn.external-login.eventival:hover {
    background: #06c1f3;
}

.btn.external-login.eventival::before {
    background: url("/storage/icons/eventival_logo.png") center center no-repeat #fff;
    background-size: 30px 30px;
}

.profile-image .image-preview {
    max-width: 300px;
}

.btn-group.selection input[type="radio"] {
    opacity: 0;
    position: absolute;
    width: 1px;
    height: 1px;
}


.btn-group.selection > input[type="radio"]:first-child + label {
    border-radius: 3px 0 0 3px !important;
}

.btn-group.selection > input[type="radio"]:last-child + label {
    border-radius: 0 3px 3px 0;
}



/* panel modal */

.panel-event .separator {
    height: 1px;
    width: 40%;
    background: #ffdd95;
    margin: auto;
    margin-bottom: 15px;
}


.panel-event .title {
    font-weight: 800;
    font-size: 18px;
}

.panel-event .info {
    display: flex;
    width: 100%;
}

.panel-event .info > div {
    flex: 1;
}

.panel-event .profile-label {
    background-color: #ffcb60 !important;
    color: #fff;
    padding: 6px;
}

.panel-event .nav-tabs-custom > .nav-tabs > li.active {
    border-top-color: #ffdd95;
}

.panel-event .icon-container {
    display: flex;
}

.panel-event .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
}

.panel-event .icon i.fa {
    margin-right: 5px;
    color: #ffcb60;
    font-size: 20px;
    margin-bottom: 5px;
}

.panel-event .no-participants {
    justify-content: center;
    align-items: center;
}

.panel-event .tz-times {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.panel-event .tz-times > div {
    margin: 0 15px 15px 15px;
}

.stats-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.stats-container .small-box {
    flex: 1;
    margin-right: 15px;
}

.stats-container .small-box:last-child {
    margin-right: 0;
}

/*
 * Component: Small Box
 * --------------------
 */
.small-box {
    border-radius: 2px;
    position: relative;
    display: block;
    margin-bottom: 20px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); }
.small-box > .inner {
    padding: 10px; }
.small-box > .small-box-footer {
    position: relative;
    text-align: center;
    padding: 3px 0;
    color: #fff;
    color: rgba(255, 255, 255, 0.8);
    display: block;
    z-index: 10;
    background: rgba(0, 0, 0, 0.1);
    text-decoration: none; }
.small-box > .small-box-footer:hover {
    color: #fff;
    background: rgba(0, 0, 0, 0.15); }
.small-box h3 {
    font-size: 38px;
    font-weight: bold;
    margin: 0 0 10px 0;
    white-space: nowrap;
    padding: 0; }
.small-box p {
    font-size: 15px; }
.small-box p > small {
    display: block;
    color: #f9f9f9;
    font-size: 13px;
    margin-top: 5px; }
.small-box h3, .small-box p {
    z-index: 5px; }
.small-box .icon {
    transition: all 0.3s linear;
    position: absolute;
    top: -10px;
    right: 10px;
    z-index: 0;
    font-size: 90px;
    color: rgba(0, 0, 0, 0.15); }
.small-box:hover {
    text-decoration: none;
    color: #f9f9f9; }
.small-box:hover .icon {
    font-size: 95px; }

@media (max-width: 767px) {
    .small-box {
        text-align: center; }
    .small-box .icon {
        display: none; }
    .small-box p {
        font-size: 12px; } }


@media all and (max-width: 240px) {
    #toast-container > div {
        padding: 8px 8px 8px 50px;
        width: 11em;
    }

    #toast-container .toast-close-button {
        right: -.2em;
        top: -.2em;
    }
}

@media all and (min-width: 241px) and (max-width: 480px) {
    #toast-container > div {
        padding: 8px 8px 8px 50px;
        width: 18em;
    }

    #toast-container .toast-close-button {
        right: -.2em;
        top: -.2em;
    }
    /*
    ul.pagination .paginate_button{
        display: none;
    }

    ul.pagination .paginate_button.previous, ul.pagination .paginate_button.next{
        display: inline-block;
    }
    */

    div.dataTables_wrapper div.dataTables_filter {
        float: left;
    }

    div.dataTables_wrapper div.dataTables_length label
    {
        float: right;
        padding-right: 0;
    }

    .availabilities .buttons-container
    {
        text-align: center;
    }

    .dataTable
    {
        font-size: 11px;
    }

    footer .navbar-nav
    {
        margin: 0;
    }

    table.agenda
    {
        font-size: 11px;
    }

    .star-wrapper
    {
        font-size: 15px;
        min-width: 100px;
    }
}

@media all and (min-width: 481px) and (max-width: 768px) {
    #toast-container > div {
        padding: 15px 15px 15px 50px;
        width: 25em;
    }
}

@media (max-width: 768px) {
    .quantity {
        width: 23px;
        padding-right: 40px !important;
    }

    .price {
        position: absolute;
        right: 42px;
    }

    .row {
        padding-bottom: 10px;
    }

    .totals {
        padding: 0;
    }

    .totals .price {
        position: static;
    }
}

@media (max-width: 992px) {
    .login-buttons {
        display: block;
    }

    .bigcart {
        margin: 0 auto 40px auto;
    }

    .col-md-5.col-sm-12 h1 {
        text-align: center;
    }

    .totals {
        box-shadow: 0 0 0;
    }

    #wrap
    {
        padding-bottom: 110px;
    }
}

@media(max-width: 481px)
{
    .info-box.level .info-box-icon > i
    {
        font-size: 26px;
    }


    .info-box.level .info-box-text
    {
        font-size: 12px;
    }

    .info-box.level
    {
        min-height: 60px;
    }

    .info-box.level .info-box-number
    {
        font-size: 15px;
    }

    .info-box.level .info-box-icon,
    .info-box.level .toggle-button
    {
        width: 60px;
        height: 60px;
    }

    .info-box.level .info-box-content
    {
        margin-left: 60px;
        margin-right: 60px;
    }

    .info-box.level .toggle-button i
    {
        font-size: 15px;
    }

    .profile-avatar
    {
        max-width: 80px;
        max-height: 80px;
    }

    .profile-avatar > img
    {
        width: 80px;
        height: 80px;
    }

    .msg-container .msg-image .image-frame
    {
        height: 60px;
        width: 60px;
    }

    .msg-container .msg-image img
    {
        width: 50px;
        height: 50px;
    }

    .msg-container.right .msg-text
    {
        padding-left: 15px;
        padding-right: 30px;
    }

    .msg-container.left .msg-text
    {
        padding-right: 15px;
        padding-left: 30px;
    }

    .profile p.form-control-show
    {
        padding: 0;
    }

}
@media print {
    * {
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -ms-transition: none !important;
        -o-transition: none !important;
        transition: none !important;
    }

    th.print-only, td.print-only {
        display: table-cell !important;
        height: 40px;
    }

    .print-only {
        display: block !important;
    }

    .no-print {
        display: none !important;
    }

    .content-header, .content-header h1 {
        display: block;
    }

    .content-header h1 small {
        display: none !important;
    }

    .pagebreak {
        display: block;
        page-break-before: always;
    }

    .table > thead > tr > th, .table > thead > tr > td, .table > tbody > tr > th, .table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td {
        padding: 4px;
    }

    tr.bg-danger {
        background-color: #f2dede !important;
        -webkit-print-color-adjust: exact;
    }

    a[href]:after {
        content: none !important;
    }
}

@media (max-width: 400px) {
    .panel-event .info {
        display: block;
    }

    .panel-event .info > div {
        margin-bottom: 15px;
    }
}

